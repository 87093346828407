/* import the necessary Bootstrap files */

// overwrite theme variables
$primary: #4993CC;
// $warning: #f8991d;
// $info: #3FA592;

$navbar-height: 9vh;
$footer-height: 26vh;

.theme-navbar {
    min-height: $navbar-height;
    background-color: #fff;
    color: #000;

    .nav-link {
        color: #000;
    }

    .nav-link:hover {
        color: #fff;
        text-decoration: underline;
        background-color: #192854;
        border-radius: 2px;
    }
}

#wrapper {
    min-height: 100 - $navbar-height - $footer-height;
}

.theme-footer {
    min-height: $footer-height;
    background-color: #192854;
    color: #fff;

    a {
        color: #fff;
    }

    a:hover {
        // color: #696767
        // background-color: #623667;
    }
}

.brand-logo {
    height: auto;
    width: 200px;
}

// SWIPPER STYLES
.swiper-pagination-bullet-active {
    background-color: #4993CC !important;
  }
  .swiper-button-prev {
    color: #4993CC !important;
  }
  .swiper-button-next {
    color: #4993CC !important;
  }
  .swiper-wrapper{
      height: 94% !important; 
  }

.justificado {
    text-align: justify;
}

/* finally, import Bootstrap */
@import "~bootstrap/scss/bootstrap.scss"