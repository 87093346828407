.head {
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../../../public/assets/searchBackground.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    // background-position: bottom -140px right 0px;
    height: 300px;
    @media (max-width: 800px) {
      height: 200px;
      background-position: bottom 0px right 0px;
    }
    .fist-section{
      margin-top: 7rem;
      @media (max-width: 800px) {
        margin-top: 2rem;
      }
    }
  }
.btnSe {
color: #fff !important;
}
.btnSe:hover {
color: #fff !important;
text-decoration: underline;
}

#advancedSearchModal{
z-index: 2000;
}
.pops{
font-family: 'Poppins';
}
